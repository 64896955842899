import { useGetSupplierCertificateListQuery } from 'api/supplier-certificates';
import PropTypes from 'prop-types';
import Button from 'components/base-components/Button';
import Pagination from 'components/base-components/Pagination/Pagination';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { monthYearShort } from 'utils/date-time-formatter';
import SupplierCertificateTable from '../declaration-table.component';

const HistoryItemsTable = ({ handleShowHistoryItems, validFrom, validUntil }) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  const { id } = useParams();

  let currentPage = parseInt(searchParams.get('page'), 10);

  const certificateDeclarationId = parseInt(searchParams.get('declaration_id'), 10);

  const { data: certificatesList } = useGetSupplierCertificateListQuery({
    organizationId: id,
    certificateDeclarationId,
    page: currentPage,
  });

  const supplierCertificates = certificatesList ? certificatesList.collection : [];

  const onChangeParams = (key, value) => {
    searchParams.set(key, value);
    setSearchParams(searchParams);
  };
  const totalPages = certificatesList ? parseInt(certificatesList.pagination?.totalPages, 10) : 0;

  return (
    <div>
      <div className="supplier-certificates__history-item">
        <>
          {' '}
          {t('supplier_certificates.attributes.declaration_period')}: {monthYearShort(validFrom)} -{' '}
          {monthYearShort(validUntil)}
        </>
        <Button
          className="supplier-certificates__history-item--button"
          label={t('shared.go_back')}
          onClick={() => {
            setSearchParams({ page: 1, tab: 'supplier_certificate' });
            handleShowHistoryItems();
          }}
          size="small"
        />
      </div>
      <SupplierCertificateTable supplierCertificates={supplierCertificates} showAction={false} />
      <Pagination
        onPaginationClick={newPage => {
          onChangeParams('page', newPage);
        }}
        totalPages={totalPages}
        currentPage={currentPage}
        nextText={t('pagination.next')}
        previousText={t('pagination.prev')}
        firstText={t('pagination.first')}
        lastText={t('pagination.last')}
        className="margin-top-20"
      />
    </div>
  );
};

HistoryItemsTable.defaultProps = {
  handleShowHistoryItems: () => {},
  validFrom: null,
  validUntil: null,
};

HistoryItemsTable.propTypes = {
  handleShowHistoryItems: PropTypes.func,
  validFrom: PropTypes.node,
  validUntil: PropTypes.node,
};

export default HistoryItemsTable;
