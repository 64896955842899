import React, { useEffect, useState } from 'react';
import './history.scss';
import { useGetOrganisationHistoryQuery } from 'api/organizations';
import Card from 'components/base-components/Card';
import { useParams } from 'react-router-dom';
import Button from 'components/base-components/Button';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import Icon from 'components/base-components/Icon';
import { t } from 'i18next';
import HistoryItem from './history-item';

const OrganisationHistory = () => {
  const [historyData, setHistoryData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const { id } = useParams();

  const historyParams = {
    page: currentPage,
    id,
  };
  const { data: historyCollection = {} } = useGetOrganisationHistoryQuery(historyParams);

  const histories = historyCollection?.collection;
  const isLastPage =
    historyCollection?.pagination?.totalPages === historyCollection?.pagination?.currentPage;

  useEffect(() => {
    if (histories?.length > 0) {
      setHistoryData(prev => [...prev, ...histories]);
    }
  }, [JSON.stringify(histories)]);

  const handleShowHideHistories = () => setCurrentPage(prev => prev + 1);

  if (parseInt(historyCollection?.pagination?.totalCount, 10) === 0) {
    return <EmptyContentPlaceholder text={t('shared.placeholder.no_history')} iconName="tasks" />;
  }

  return (
    <>
      <Card>
        {historyData.map((history, index) => (
          <HistoryItem
            key={history.id}
            history={history}
            isLastItem={histories.length - 1 === index}
          />
        ))}
      </Card>
      {historyCollection?.pagination?.totalPages > 1 && !isLastPage && (
        <Button
          className="organisation__history-btn"
          label={t('common.show_more')}
          icon={<Icon name="arrowDown" />}
          fullWidth={true}
          size="small"
          onClick={() => handleShowHideHistories()}
        />
      )}
    </>
  );
};

export default OrganisationHistory;
