import React, { Fragment, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import Textarea from 'components/base-components/Textarea';
import {
  useGetSupplierCertificatesQuery,
  useCreateSupplierCertificateComplaintMutation,
  useCreateSingleSupplierCertificateComplaintMutation,
} from 'api/supplier-certificates';
import PropTypes from 'prop-types';
import Tooltip from 'components/base-components/Tooltip';
import IconButton from 'components/base-components/IconButton';
import Icon from '../../base-components/Icon';
import Button from '../../base-components/Button';
import SidePanel from '../../base-components/SidePanel';
import Input from '../../base-components/Input';

const ComplaintForm = ({ onSave, onCancel, selectedCertificate }) => {
  const { t } = useTranslation();

  return (
    <Form
      onSubmit={onSave}
      validate={() => {}}
      render={({ handleSubmit, form, values, submitErrors }) => {
        return (
          <form onSubmit={handleSubmit} className="supplier-certificates__complain-form">
            {selectedCertificate && (
              <Field name="supplier" initialValue={selectedCertificate.name}>
                {({ input, meta }) => (
                  <Input
                    size="tiny"
                    label={t('supplier_certificates.attributes.supplier_name')}
                    touched={!meta.dirtySinceLastSubmit}
                    error={meta.submitError}
                    required={true}
                    {...input}
                  />
                )}
              </Field>
            )}
            <div className="supplier-certificates__form--margin-top">
              <Field name="title">
                {({ input, meta }) => (
                  <Input
                    size="tiny"
                    label={t('supplier_certificates.comment.title.label')}
                    placeholder={t('supplier_certificates.comment.title.placeholder')}
                    touched={!meta.dirtySinceLastSubmit}
                    error={meta.submitError}
                    required={true}
                    {...input}
                  />
                )}
              </Field>
            </div>
            <div className="supplier-certificates__form--margin-top">
              <Field name="description">
                {({ input, meta }) => (
                  <Textarea
                    size="tiny"
                    label={t('supplier_certificates.comment.describe.label')}
                    placeholder={t('supplier_certificates.comment.describe.placeholder')}
                    touched={!meta.dirtySinceLastSubmit}
                    error={meta.submitError}
                    required={true}
                    {...input}
                  />
                )}
              </Field>
            </div>
            <div className="supplier-certificates__form--margin-top supplier-certificates__form-action">
              <Button
                label={t('shared.action.save')}
                size="small"
                onClick={handleSubmit}
                type="success"
              />
              <Button
                label={t('shared.action.cancel')}
                size="small"
                onClick={() => {
                  form.reset();
                  onCancel();
                }}
              />
            </div>
          </form>
        );
      }}
    />
  );
};

ComplaintForm.propTypes = {
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  organizationId: PropTypes.number.isRequired,
  selectedCertificate: PropTypes.shape().isRequired,
};

const CreateComplaint = ({
  buttonLabel,
  organizationId,
  certificateDeclarationId,
  isDisabled,
  selectedCertificate,
  showIconButton,
}) => {
  const [enableSidePanel, setEnableSidePanel] = useState(false);
  const { t } = useTranslation();

  const { data: supplierCertificatesResponse } = useGetSupplierCertificatesQuery({
    q: '',
    organizationId: organizationId,
    status: 'complainable',
  });

  const [createSupplierCertificateComplaint] = useCreateSupplierCertificateComplaintMutation();
  const [createSingleSupplierCertificateComplaint] =
    useCreateSingleSupplierCertificateComplaintMutation();

  const onSave = values => {
    const commonParams = {
      title: values.title,
      description: values.description,
    };

    const params = certificateDeclarationId
      ? {
          ...commonParams,
          organizationId: parseInt(organizationId),
          certificateDeclarationId,
        }
      : {
          ...commonParams,
          certificateId: selectedCertificate?.id,
        };

    const createComplaint = certificateDeclarationId
      ? createSupplierCertificateComplaint
      : createSingleSupplierCertificateComplaint;

    return createComplaint(params)
      .unwrap()
      .catch(({ data: { errors } }) => errors);
  };

  return (
    <Fragment>
      <SidePanel
        title={t('supplier_certificates.raise_complaint')}
        closeIcon={<Icon name="close" />}
        body={
          <ComplaintForm
            onSave={onSave}
            onCancel={() => setEnableSidePanel(false)}
            organizationId={organizationId}
            selectedCertificate={selectedCertificate}
          />
        }
        isOpened={enableSidePanel}
        onClick={() => {}}
        onClose={() => {
          setEnableSidePanel(false);
        }}
      />
      {showIconButton ? (
        <Tooltip content={buttonLabel} type="inverse" size="tiny">
          <IconButton
            icon={<Icon name="warning" />}
            size="tiny"
            onClick={() => setEnableSidePanel(true)}
          />
        </Tooltip>
      ) : (
        <Button
          label={buttonLabel}
          size="small"
          disabled={isDisabled || supplierCertificatesResponse?.certificates.length === 0}
          onClick={() => setEnableSidePanel(true)}
        />
      )}
    </Fragment>
  );
};

CreateComplaint.defaultProps = {
  selectedCertificate: null,
  certificateDeclarationId: null,
  showIconButton: false,
};

CreateComplaint.propTypes = {
  buttonLabel: PropTypes.string.isRequired,
  organizationId: PropTypes.number.isRequired,
  certificateDeclarationId: PropTypes.number,
  isDisabled: PropTypes.bool.isRequired,
  showIconButton: PropTypes.bool,
  selectedCertificate: PropTypes.shape().isRequired,
};

export default CreateComplaint;
