import Button from 'components/base-components/Button';
import Icon from 'components/base-components/Icon';
import Input from 'components/base-components/Input';
import Pagination from 'components/base-components/Pagination';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import SidePanel from 'components/base-components/SidePanel';
import useDebounce from 'hooks/useDebounce';
import InvoiceFilterModal from '../filter/invoice-filter-modal';
import FlatFileForm from '../Form/flat-file/index.component';
import CreateJob from '../Form/create-job/index.component';

const SharedInputFilterCreateComponent = ({
  searchQuery,
  setSearchQueryParams,
  currentTab,
  currentPage,
  children,
  collection,
  searchInputPlaceholder,
  navigateToNewPage,
  showFilterButton,
  createButton,
  sidePanelTitle,
}) => {
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isSidepanelOpen, setIsSidepanelOpen] = useState(false);
  const [searchInput, setSearchInput] = useState('');
  const onSearch = value => {
    setSearchInput(value);
    setSearchQueryInParams(value);
  };

  const setSearchQueryInParams = useDebounce(value =>
    setSearchQueryParams({ q: value, tab: currentTab, page: 1 }),
  );

  useEffect(() => {
    setSearchInput(searchQuery);
  }, []);

  const formComponent = {
    flat_file: <FlatFileForm setIsSidepanelOpen={setIsSidepanelOpen} />,
    jobs: <CreateJob setIsSidepanelOpen={setIsSidepanelOpen} />,
  };

  return (
    <>
      <div className="invoice__header--wrapper">
        <div className="invoice-list__search-tab">
          <Input
            className="invoice-list__search"
            placeholder={searchInputPlaceholder}
            preIcon={<Icon size="small" name="search" />}
            size="tiny"
            value={searchInput}
            onChange={onSearch}
          />
          {showFilterButton && (
            <Button
              onClick={() => setIsFilterVisible(true)}
              label={
                <span className="invoice-list__filter-button">
                  <Icon fill="none" name="filter" />
                  <span>{t('invoice.filter')}</span>
                </span>
              }
              size="small"
            />
          )}
        </div>
        {createButton && (
          <div
            className="invoice-list__create-button"
            onClick={() => (navigateToNewPage ? {} : setIsSidepanelOpen(true))}
          >
            {createButton}
          </div>
        )}
      </div>
      {children}
      <Pagination
        className="margin-top-20"
        onPaginationClick={newPage => {
          setSearchQueryParams({ page: newPage, tab: currentTab });
        }}
        totalPages={collection && parseInt(collection.pagination?.totalPages, 10)}
        currentPage={currentPage}
        firstText={t('pagination.first')}
        lastText={t('pagination.last')}
        nextText={t('pagination.next')}
        previousText={t('pagination.prev')}
      />
      <InvoiceFilterModal
        setFilterQueryParams={() => {}}
        isFilterVisible={isFilterVisible}
        setIsFilterVisible={setIsFilterVisible}
      />

      <SidePanel
        isOpened={isSidepanelOpen}
        title={sidePanelTitle}
        body={isSidepanelOpen && formComponent?.[currentTab]}
        onClose={() => setIsSidepanelOpen(false)}
      />
    </>
  );
};

SharedInputFilterCreateComponent.defaultProps = {
  setSearchQueryParams: () => {},
  searchQuery: null,
  currentTab: null,
  currentPage: null,
  children: null,
  collection: null,
  searchInputPlaceholder: null,
  navigateToNewPage: false,
  showFilterButton: true,
  createButton: null,
  sidePanelTitle: '',
};

SharedInputFilterCreateComponent.propTypes = {
  setSearchQueryParams: PropTypes.func,
  searchQuery: PropTypes.string,
  currentTab: PropTypes.string,
  sidePanelTitle: PropTypes.string,
  currentPage: PropTypes.string,
  searchInputPlaceholder: PropTypes.string,
  children: PropTypes.node,
  navigateToNewPage: PropTypes.bool,
  showFilterButton: PropTypes.bool,
  createButton: PropTypes.node,
  collection: PropTypes.shape(),
};

export default SharedInputFilterCreateComponent;
