import React, { Fragment, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useParams } from 'react-router';
import classNames from 'classnames';
import Button from 'components/base-components/Button';
import Badge from 'components/base-components/Badge';
import useDebounce from 'hooks/useDebounce';
import Icon from '../../base-components/Icon';
import Input from '../../base-components/Input';
import '../organisations.styles.scss';
import OrganizationsTable from './table.component';
import OrganisationFilterModal from './filter/organisation-filter-modal';

const TabContent = ({ searchQuery, organizations, onChangeParams, isLoading }) => {
  const [totalFilterCount, setTotalFilterCount] = useState(0);
  const [searchInput, setSearchInput] = useState('');
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const { id } = useParams();
  const { t } = useTranslation();

  const setSearchQueryInParams = useDebounce(() => {
    onChangeParams({ q: searchInput, page: 1 });
  });

  const onSearch = value => {
    setSearchInput(value);
    setSearchQueryInParams(value);
  };

  useEffect(() => {
    setSearchInput(searchQuery);
  }, []);

  return (
    <Fragment>
      <div className="organisation__search-tab">
        <Input
          className="organisation__search"
          placeholder={t('organisation.search_organisation')}
          preIcon={<Icon name="search" />}
          size="tiny"
          value={searchInput}
          onChange={onSearch}
        />
        <Button
          className={classNames({ 'organisation__search-tab__filter-button': totalFilterCount })}
          onClick={() => setIsFilterVisible(true)}
          label={
            <span className="organisation__filter-button">
              <Icon name="filter" />
              <span>{t('invoice.filter')}</span>
              {totalFilterCount > 0 && (
                <Badge
                  classNameForBadge="modal__content--checkbox-badge"
                  label={totalFilterCount}
                  type="default"
                />
              )}
            </span>
          }
          size="small"
        />
      </div>
      <div className="col-12 col-bleed-x">
        <OrganizationsTable
          organizations={organizations}
          isLoading={isLoading}
          parentOrganisationId={id}
          onChangeParams={onChangeParams}
        />
      </div>
      <OrganisationFilterModal
        setFilterQueryParams={onChangeParams}
        isFilterVisible={isFilterVisible}
        setIsFilterVisible={setIsFilterVisible}
        setTotalFilterCount={setTotalFilterCount}
      />
    </Fragment>
  );
};

TabContent.defaultProps = {
  searchQuery: '',
  organizations: null,
  onChangeParams: () => {},
  isLoading: false,
};

TabContent.propTypes = {
  searchQuery: PropTypes.string,
  organizations: PropTypes.shape(),
  onChangeParams: PropTypes.func,
  isLoading: PropTypes.bool,
  selectedFilters: PropTypes.shape().isRequired,
};

export default TabContent;
