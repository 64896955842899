import { PillTabs } from 'components/base-components/PillTabs';
import { t } from 'i18next';
import React, { useCallback, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import SharedInputFilterCreateComponent from 'components/invoices/shared/input-filter-create.component';
import InvoiceFlatFileTable from 'components/invoices/list/flat-file/table.component';
import { useGetFlatFilesQuery } from 'api/documents';
import { CONVERTED, UPLOADED } from 'utils/flat-file-types';
import Button from 'components/base-components/Button';

const InvoiceFlatFile = () => {
  const TAB_MAPPER = {
    0: UPLOADED,
    1: CONVERTED,
  };
  const [selectedTab, setSelectedTab] = useState();
  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
    q: '',
    status: UPLOADED,
  });
  const currentPage = parseInt(searchParams.get('page'), 10);
  const currentTab = searchParams.get('tab');
  const searchQuery = searchParams.get('q');
  const currentStatuses = searchParams.get('status');

  const setSearchQueryParams = useCallback(
    params => {
      Object.keys(params).forEach(key => {
        searchParams.set(key, params[key]);
      });
      setSearchParams(searchParams);
    },
    [currentPage],
  );

  const flatFileParams = {
    'statuses[]': [currentStatuses],
    page: currentPage,
  };

  const { data: flatFileCollection = {}, isFetching } = useGetFlatFilesQuery(flatFileParams);
  const flatFiles = flatFileCollection?.collection || [];

  const tabItems = [UPLOADED, CONVERTED].map((item, idx) => ({
    getContent: () => (
      <SharedInputFilterCreateComponent
        searchQuery={searchQuery}
        setSearchQueryParams={setSearchQueryParams}
        currentTab={currentTab}
        currentPage={currentPage}
        searchInputPlaceholder={t('invoice.search_flat_file')}
        collection={flatFileCollection}
        key={item}
        showFilterButton={false}
        createButton={
          <Button type="success" size="small" label={t('invoice.flat_file.upload_btn')} />
        }
        sidePanelTitle={t('invoice.flat_file.form.title')}
      >
        <InvoiceFlatFileTable
          flatFiles={flatFiles}
          currentStatuses={currentStatuses}
          isFetching={isFetching}
        />
      </SharedInputFilterCreateComponent>
    ),
    key: idx,
    title: t(`invoice.flat_file.tab.${item}`),
  }));

  return (
    <>
      <PillTabs
        items={tabItems}
        onChange={value => {
          setSelectedTab(value);
          setSearchQueryParams({ status: TAB_MAPPER[value], page: 1 });
        }}
        selectedTabKey={selectedTab}
      />
    </>
  );
};

export default InvoiceFlatFile;
