import React from 'react';
import PropTypes from 'prop-types';
import { t } from 'i18next';
import AmountComponent from 'components/shared-components/Amount';
import numberFormatter from 'utils/number-formatter';

const InvoiceSummary = ({ invoice }) => {
  const { totalAmountExclVat, totalAmountInclVat, totalVatAmountsByVatRates } = invoice;

  const getVatAmountRates = item =>
    totalVatAmountsByVatRates
      ?.filter(totalVatAmount => totalVatAmount.percentage && totalVatAmount.percentage !== '0.0')
      .map((totalVatAmount, index) => (
        <div key={index} className="invoice__summary-item">
          {item === 'title' ? (
            <span>
              {t('invoice.total_vat_with_percentage', {
                percentage: totalVatAmount.percentage,
              })}
            </span>
          ) : (
            <AmountComponent value={numberFormatter(totalVatAmount.value)} />
          )}
        </div>
      ));

  return (
    <>
      <div className="invoice__summary">
        <div className="invoice__summary--titles">
          <span> {t('invoice.total_net_amount')}</span>
          {getVatAmountRates('title')}
        </div>
        <div className="invoice__summary--values">
          <span>
            {t('invoice.chf')} {totalAmountExclVat}
          </span>
          {getVatAmountRates()}
        </div>
      </div>
      <div className="invoice__summary invoice__summary--border-right">
        <div className="invoice__summary--titles">
          <span className="invoice__summary--titles-bold">{t('invoice.total_amount_chf')}</span>
          <span>{t('invoice.paid_chf')}</span>
        </div>
        <div className="invoice__summary--values">
          <span className="invoice__summary--values-bold">
            {t('invoice.chf')} {totalAmountInclVat}
          </span>
          <AmountComponent value={invoice?.totalReceivedAmount} />
        </div>
      </div>
      <div className="invoice__summary">
        <div className="invoice__summary--titles">
          <span className="invoice__summary--titles-bold">{t('invoice.remaining_due')}</span>
        </div>
        <div className="invoice__summary--values">
          <span className="invoice__summary--values-bold">
            <AmountComponent value={invoice?.totalRemainingDue} />
          </span>
        </div>
      </div>
    </>
  );
};

InvoiceSummary.propTypes = {
  invoice: PropTypes.shape().isRequired,
};

export default InvoiceSummary;
