import { Tabs } from 'components/base-components/RTabs';
import { JOBS } from 'components/invoices/helpers/invoice-tag-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import InvoiceGroupListComponent from '../group-list/index.component';
import InvoiceJobListComponent from '../job-list/index.component';
import '../invoice-list.styles.scss';
import InvoiceFlatFile from '../flat-file/index.component';

const InvoiceWrapper = () => {
  const [searchParams, setSearchParams] = useSearchParams({
    page: 1,
    tab: JOBS,
  });
  const TABS = {
    jobs: 0,
    draft: 1,
    open: 2,
    paid: 3,
    cancelled: 4,
    failed: 5,
    flat_file: 6,
  };
  const { t } = useTranslation();
  const currentTab = searchParams.get('tab') || TABS.jobs;

  const changeTab = value => {
    const tab = Object.keys(TABS).find(key => TABS[key] === value);
    setSearchParams({ tab });
  };

  return (
    <div className="user__rtabs invoice__user-rtabs">
      <Tabs
        controlled={true}
        selectedTabKey={TABS[currentTab]}
        items={[
          {
            title: t('invoice.tag_status.invoice_jobs'),
            key: 0,
            getContent: () => <InvoiceJobListComponent key={0} currentTab={currentTab} />,
          },
          {
            title: t('invoice.tag_status.draft'),
            key: 1,
            getContent: () => <InvoiceGroupListComponent key={1} currentTab={currentTab} />,
          },
          {
            title: t('invoice.tag_status.open'),
            key: 2,
            getContent: () => <InvoiceGroupListComponent key={2} currentTab={currentTab} />,
          },
          {
            title: t('invoice.tag_status.paid'),
            key: 3,
            getContent: () => <InvoiceGroupListComponent key={3} currentTab={currentTab} />,
          },
          {
            title: t('invoice.tag_status.cancelled'),
            key: 4,
            getContent: () => <InvoiceGroupListComponent key={4} currentTab={currentTab} />,
          },
          {
            title: t('invoice.tag_status.failed'),
            key: 5,
            getContent: () => <InvoiceGroupListComponent key={5} currentTab={currentTab} />,
          },
          {
            title: t('invoice.tag_status.flat_file'),
            key: 6,
            getContent: () => <InvoiceFlatFile key={6} currentTab={currentTab} />,
          },
        ]}
        onChange={changeTab}
      />
    </div>
  );
};

export default InvoiceWrapper;
