import {
  useCorrectInvoiceMutation,
  useIssueCreditNoteMutation,
  useReleaseSingleInvoiceMutation,
  useUpdateInvoiceMutation,
} from 'api/invoices';
import { useGetOrganizationQuery } from 'api/organizations';
import { useGetCurrentUserQuery } from 'api/users';
import CheckBox from 'components/base-components/CheckBox';
import EmphasisTag from 'components/base-components/EmphasisTag';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import Popover from 'components/base-components/Popover';
import SidePanel from 'components/base-components/SidePanel';
import Spinner from 'components/base-components/Spinner';
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/base-components/Table';
import InvoiceStatusTag from 'components/invoices/helpers/invoice-tag.component';
import OrganizationName from 'components/organisations/organization-name';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import WarningModal from 'components/shared-components/modal/warning';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import TableLoaderPlaceholder from 'components/shared-components/placeholders/table-loader-placeholder';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { dateMonthYear } from 'utils/date-time-formatter';
import { CANCELLED, DRAFT, OPEN, PAID } from 'utils/invoice-statuses';
import { INDIVIDUAL } from 'utils/invoice-types';
import isBioSuisseAdmin from 'utils/is-bio-suisse-admin';
import { removeEmptyFromObject } from 'utils/object-cleanup';
import ORGANISATION_TYPE from 'utils/organisation-type';
import { Trans } from 'react-i18next';
import { PRODUCER } from 'utils/organisation-industries';
import SortListComponent from 'components/shared-components/sort-list.component';
import ReleaseInvoiceForm from '../Form/release/index.component';
import CorrectInvoiceForm from '../Form/correct/index.component';
import BulkActionComponent from './bulk-action.component';
import DownloadPdf from './download-pdf.component';

const InvoiceTableRow = ({
  invoice,
  enableBulkAction,
  handleInvoiceSelect,
  selectedInvoices,
  organisationId,
  allInvoiceSelected,
  onCorrect,
  onCancelInvoice,
  onIssueCreditNote,
}) => {
  const { id, invoiceId } = invoice;
  const isChecked =
    (allInvoiceSelected && !(selectedInvoices[id] === false)) || selectedInvoices[id];
  const [showPopover, setShowPopover] = useState(false);
  const [enableReleaseSidePannel, setEnableReleaseSidePanel] = useState(false);
  const [isSidepanelOpen, setIsSidepanelOpen] = useState(false);
  const { data: organization } = useGetOrganizationQuery(invoice.organizationId);
  const { data: currentUser } = useGetCurrentUserQuery();

  const navigationLinks = useNavigationLinks();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isIssueCreditNoteModalOpen, setIsIssueCreditNoteModalOpen] = useState(false);

  const invoiceType =
    organization?.industry === PRODUCER
      ? t(`invoice.type_options.${invoice.type}`)
      : t(`invoice.types.${invoice.type}`);

  return (
    <>
      <TableRow className="organisation__table-row">
        {enableBulkAction && (
          <TableData>
            <CheckBox
              key={id}
              className="modal__content__checkbox--option"
              isChecked={isChecked}
              size="tiny"
              onChange={e => handleInvoiceSelect(id, e.target.checked)}
            />
          </TableData>
        )}
        <TableData>
          {invoiceId || t('common.not_applicable')}{' '}
          {invoice.willBeReleased && (
            <EmphasisTag
              type={'accent'}
              size="tiny"
              text={t('invoice.tag_status.scheduled_for_release')}
            />
          )}
        </TableData>
        {organisationId ? (
          <TableData>{invoice.title}</TableData>
        ) : (
          <>
            <TableData>
              {organization ? (
                <OrganizationName fetchedOrganisation={organization} />
              ) : (
                <Spinner bgColor="none" color="success" size="tiny" />
              )}
            </TableData>
            <TableData>
              {organization ? (
                <EmphasisTag
                  className="emphasis-tag--left-margin-none"
                  type="normal"
                  size="tiny"
                  text={
                    organization.type === ORGANISATION_TYPE.controlBody
                      ? t('organisation.details.tabs.control')
                      : t(`organisation.industry.${organization.industry}`)
                  }
                />
              ) : (
                <Spinner bgColor="none" color="success" size="tiny" />
              )}
            </TableData>
          </>
        )}
        <TableData>{invoiceType}</TableData>
        <TableData>{dateMonthYear(invoice.invoiceDate)}</TableData>
        <TableData>{dateMonthYear(invoice.dueDate)}</TableData>
        <TableData>
          <InvoiceStatusTag invoice={invoice} />
        </TableData>
        <TableData align="right">{invoice.totalAmountInclVat}</TableData>
        <TableData align="center">
          <Popover
            className="task__popover-wrapper"
            isVisible={showPopover}
            onOutsideClick={() => setShowPopover(false)}
            content={
              <div className="task__popover">
                {invoice.status === OPEN && (
                  <div
                    className="task__popover-content"
                    onClick={() => {
                      setIsSidepanelOpen(true);
                      setShowPopover(false);
                    }}
                  >
                    {t('invoice.correct.title')}
                  </div>
                )}
                <Link
                  to={navigationLinks.invoiceShowPage({
                    organizationId: invoice.organizationId,
                    invoiceId: invoice.id,
                  })}
                >
                  <div className="task__popover-content">
                    <span>{t('invoice.view')}</span>
                  </div>
                </Link>
                {invoice.type === INDIVIDUAL &&
                  (invoice.status === DRAFT || invoice.status === OPEN) &&
                  isBioSuisseAdmin(currentUser) && (
                    <Link to={navigationLinks.invoiceEditPage(invoice.id)}>
                      <div className="task__popover-content">
                        <span>{t('shared.action.edit')}</span>
                      </div>
                    </Link>
                  )}
                {isBioSuisseAdmin(currentUser) &&
                  invoice.status === DRAFT &&
                  !invoice.willBeReleased && (
                    <div
                      onClick={() => {
                        setShowPopover(false);
                        setEnableReleaseSidePanel(true);
                      }}
                      className="task__popover-content"
                    >
                      {t('invoice.release.release_invoice')}
                    </div>
                  )}
                {isBioSuisseAdmin(currentUser) &&
                  (invoice.status === DRAFT || invoice.status === OPEN) && (
                    <div
                      onClick={() => {
                        setShowPopover(false);
                        setIsDeleteModalOpen(true);
                      }}
                      className="task__popover-content"
                    >
                      {t('invoice.cancel_invoice')}
                    </div>
                  )}
                <div className="task__popover-content">
                  <DownloadPdf invoiceId={invoice.id} type="anchor" />
                </div>
                {invoice.status !== DRAFT &&
                  invoice.status !== PAID &&
                  invoice.status !== CANCELLED &&
                  isBioSuisseAdmin(currentUser) && (
                    <Link to={navigationLinks.invoiceRecordPayment(invoice.id)}>
                      <div className="task__popover-content">
                        <span>{t('invoice.record_payment')}</span>
                      </div>
                    </Link>
                  )}
                {invoice.status === PAID && isBioSuisseAdmin(currentUser) && (
                  <div
                    className="task__popover-content"
                    onClick={() => {
                      setIsIssueCreditNoteModalOpen(true);
                      setShowPopover(false);
                    }}
                  >
                    {t('invoice.issue_credit_note')}
                  </div>
                )}
              </div>
            }
          >
            <IconButton
              icon={<Icon name="horizontalDots" />}
              color="default"
              onClick={() => setShowPopover(!showPopover)}
              isIconOnly={false}
            />
          </Popover>
        </TableData>
      </TableRow>
      <SidePanel
        isOpened={isSidepanelOpen}
        title={t('invoice.correct.title')}
        body={
          isSidepanelOpen && (
            <CorrectInvoiceForm
              organisationId={invoice.organizationId}
              onCorrect={onCorrect}
              setIsSidepanelOpen={setIsSidepanelOpen}
              invoice={invoice}
            />
          )
        }
        onClose={() => setIsSidepanelOpen(false)}
        onClick={() => setIsSidepanelOpen(false)}
      />
      <SidePanel
        title={t('invoice.release.release_invoice')}
        closeIcon={<Icon name="close" />}
        body={
          <ReleaseInvoiceForm
            setEnableSidePanel={setEnableReleaseSidePanel}
            callbackMutation={useReleaseSingleInvoiceMutation}
            entityId={invoice.id}
            releaseInvoiceJob={false}
            scheduledFor={invoice.invoiceDate}
          />
        }
        isOpened={enableReleaseSidePannel}
        onClose={() => setEnableReleaseSidePanel(false)}
      />
      <WarningModal
        onOutsideClick={() => setIsDeleteModalOpen(false)}
        isVisible={isDeleteModalOpen}
        title={t('invoice.cancel_modal.warning_title')}
        content={
          <Trans
            i18nKey="invoice.cancel_modal.warning_description"
            values={{ invoiceId: invoice.invoiceId }}
          />
        }
        confirmActionText={t('shared.action.yes')}
        cancelActionText={t('shared.action.no')}
        onCancel={() => setIsDeleteModalOpen(false)}
        onConfirm={() => onCancelInvoice(invoice.id)}
      />
      <WarningModal
        onOutsideClick={() => setIsIssueCreditNoteModalOpen(false)}
        isVisible={isIssueCreditNoteModalOpen}
        title={t('invoice.issue_credit_note_modal.warning_title')}
        content={
          <Trans
            i18nKey="invoice.issue_credit_note_modal.warning_description"
            values={{ invoiceId: invoice.invoiceId }}
          />
        }
        confirmActionText={t('shared.action.yes')}
        cancelActionText={t('shared.action.no')}
        onCancel={() => setIsIssueCreditNoteModalOpen(false)}
        onConfirm={() => onIssueCreditNote(invoice.id)}
      />
    </>
  );
};

export const InvoiceTableHeader = props => {
  const {
    organisationId,
    enableBulkAction,
    selectCurrentPageInvoices,
    invoices,
    selectedInvoices,
    allInvoiceSelected,
  } = props;
  const isAllItemSelectedInPage = () => {
    if (!invoices.length) return false;

    return allInvoiceSelected
      ? !invoices?.some(({ id }) => selectedInvoices[id] === false)
      : invoices?.every(({ id }) => selectedInvoices[id]);
  };

  return (
    <TableHead>
      <TableRow>
        {enableBulkAction && (
          <TableHeader>
            <CheckBox
              isChecked={isAllItemSelectedInPage()}
              onChange={e => selectCurrentPageInvoices(e.target.checked)}
              size="tiny"
            />
          </TableHeader>
        )}
        <TableHeader>
          <SortListComponent text={t('invoice.id')} field="id" />
        </TableHeader>
        {organisationId ? (
          <TableHeader>
            <SortListComponent text={t('invoice.invoice_title')} field="title" />
          </TableHeader>
        ) : (
          <>
            <TableHeader>{t('invoice.invoice_to')}</TableHeader>
            <TableHeader>{t('user_details.department')}</TableHeader>
          </>
        )}
        <TableHeader>
          <SortListComponent text={t('invoice.type')} field="type" />
        </TableHeader>
        <TableHeader>
          <SortListComponent text={t('invoice.date')} field="invoice_date" />
        </TableHeader>
        <TableHeader>
          <SortListComponent text={t('invoice.due_date')} field="due_date" />
        </TableHeader>

        <TableHeader>{t('shared.status')}</TableHeader>
        <TableHeader align="right">{t('invoice.amount')}</TableHeader>
        <TableHeader align="center">{t('shared.actions')}</TableHeader>
      </TableRow>
    </TableHead>
  );
};

const InvoiceTable = ({
  invoices,
  isFetching,
  organisationId,
  enableBulkAction,
  totalInvoiceCount,
  statuses,
}) => {
  const [selectedInvoices, setSelectedInvoices] = useState({});
  const [allInvoiceSelected, setAllInvoiceSelected] = useState(false);
  const showBulkActionComponent =
    Object.values(selectedInvoices).filter(value => value).length !== 0 || allInvoiceSelected;

  const clearSelectedInvoices = () => {
    setSelectedInvoices({});
    setAllInvoiceSelected(false);
  };

  const [updateInvoice, status] = useUpdateInvoiceMutation();
  const cancelInvoice = id => {
    updateInvoice({ invoiceId: id, payload: { status: CANCELLED } }).unwrap();
  };

  const [correctInvoice, { isLoading, isSuccess, isError, requestId }] =
    useCorrectInvoiceMutation();

  const [issueCreditNote, issueCreditNoteStatus] = useIssueCreditNoteMutation();

  const onCorrect = (values, id) => {
    const params = removeEmptyFromObject({
      'general_information[zone_nr]': values.zone,
      'general_information[demeter]': values.demeter,
      'general_information[summer_pasture_holdings]': values.summerOperation,
      'general_information[open_arable_land_in_m2]': values.openLand,
      'general_information[crops_in_protected_cultivation_in_m2]': values.protectedCultivation,
      'general_information[special_and_permanent_crops_in_m2]': values.specialCrops,
      'general_information[livestock_in_dgve]': values.livestockInDgve,
      'general_information[agricultural_usable_area_total_in_m2]': values.usableArea,
      'general_information[grassland_in_m2]': values.greenArea,
      invoice_date: values.invoiceDate,
      change_general_information: values.changeGeneralDataCheckbox,
    });

    let formData = new FormData();
    for (const key in params) {
      formData.append(key, params[key]);
    }

    return correctInvoice({
      id: id,
      payload: formData,
    }).then(() => {});
  };

  const onIssueCreditNote = id => issueCreditNote({ id }).unwrap();

  useEffect(() => {
    const selectedCount = Object.values(selectedInvoices).filter(id => !id).length;
    if (parseInt(totalInvoiceCount) === selectedCount) {
      clearSelectedInvoices();
    }
  }, [Object.values(selectedInvoices).length, totalInvoiceCount]);

  const selectAllInvoices = () => {
    setAllInvoiceSelected(true);
    setSelectedInvoices({});
  };

  const selectCurrentPageInvoices = value => {
    const mappedObject = {};
    invoices.forEach(invoice => (mappedObject[invoice.id] = value));

    setSelectedInvoices(prev => ({ ...prev, ...mappedObject }));
  };

  const handleInvoiceSelect = (id, value) =>
    setSelectedInvoices(prev => ({ ...prev, [id]: value }));

  return (
    <>
      {!isFetching && invoices.length === 0 ? (
        <EmptyContentPlaceholder iconName="invoice" text={t('invoice.no_invoice')} />
      ) : (
        <>
          {showBulkActionComponent && (
            <BulkActionComponent
              selectedInvoices={selectedInvoices}
              clearSelectedInvoices={clearSelectedInvoices}
              statuses={statuses}
              selectAllInvoices={selectAllInvoices}
              allInvoiceSelected={allInvoiceSelected}
              totalInvoiceCount={totalInvoiceCount}
            />
          )}
          <Table className="organisation_table">
            <InvoiceTableHeader
              enableBulkAction={enableBulkAction}
              organisationId={organisationId}
              selectCurrentPageInvoices={selectCurrentPageInvoices}
              invoices={invoices}
              selectedInvoices={selectedInvoices}
              allInvoiceSelected={allInvoiceSelected}
            />
            <TableBody>
              {isFetching ? (
                <TableLoaderPlaceholder numberOfRows={10} numberOfColumns={9} />
              ) : (
                invoices.map(invoice => (
                  <InvoiceTableRow
                    enableBulkAction={enableBulkAction}
                    organisationId={organisationId}
                    key={`invoice-list-${invoice.id}`}
                    invoice={invoice}
                    selectedInvoices={selectedInvoices}
                    handleInvoiceSelect={handleInvoiceSelect}
                    allInvoiceSelected={allInvoiceSelected}
                    onCorrect={onCorrect}
                    onCancelInvoice={cancelInvoice}
                    onIssueCreditNote={onIssueCreditNote}
                  />
                ))
              )}
            </TableBody>
          </Table>
        </>
      )}
      <SubmitModal
        isSuccess={isSuccess}
        isLoading={isLoading}
        isError={isError}
        requestId={requestId}
        successTitle={t('invoice.correct.modal.success_title')}
        successContent={t('invoice.correct.modal.success_description')}
        errorTitle={t('invoice.correct.modal.error_title')}
        showError={true}
        showLoader={true}
      />
      <SubmitModal
        isSuccess={status?.isSuccess}
        isLoading={status?.isLoading}
        requestId={status?.requestId}
        successTitle={t('invoice.cancel_modal.title')}
        successContent={t('invoice.cancel_modal.description')}
        showError={false}
        showLoader={false}
      />
      <SubmitModal
        isSuccess={issueCreditNoteStatus?.isSuccess}
        isLoading={issueCreditNoteStatus?.isLoading}
        isError={issueCreditNoteStatus?.isError}
        requestId={issueCreditNoteStatus?.requestId}
        successTitle={t('invoice.issue_credit_note_modal.success_title')}
        successContent={t('invoice.issue_credit_note_modal.success_description')}
        errorTitle={t('invoice.issue_credit_note_modal.error_title')}
        errorContent={t('invoice.issue_credit_note_modal.error_description')}
        showError={true}
        showLoader={true}
      />
    </>
  );
};

InvoiceTableHeader.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  organisationId: PropTypes.number.isRequired,
  enableBulkAction: PropTypes.bool.isRequired,
  selectCurrentPageInvoices: PropTypes.func.isRequired,
  selectedInvoices: PropTypes.arrayOf(PropTypes.number).isRequired,
  excludedInvoices: PropTypes.arrayOf(PropTypes.number).isRequired,
  allInvoiceSelected: PropTypes.bool.isRequired,
};

InvoiceTableRow.propTypes = {
  invoice: PropTypes.shape().isRequired,
  organisationId: PropTypes.number.isRequired,
  enableBulkAction: PropTypes.bool.isRequired,
  handleInvoiceSelect: PropTypes.func.isRequired,
  selectedInvoices: PropTypes.arrayOf(PropTypes.number).isRequired,
  excludedInvoices: PropTypes.arrayOf(PropTypes.number).isRequired,
  allInvoiceSelected: PropTypes.bool.isRequired,
  onCorrect: PropTypes.func.isRequired,
  onCancelInvoice: PropTypes.func.isRequired,
  onIssueCreditNote: PropTypes.func.isRequired,
};

InvoiceTable.defaultProps = {
  isFetching: false,
  organisationId: null,
  enableBulkAction: false,
  totalInvoiceCount: '',
};

InvoiceTable.propTypes = {
  invoices: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isFetching: PropTypes.bool,
  organisationId: PropTypes.number,
  enableBulkAction: PropTypes.bool,
  statuses: PropTypes.arrayOf(PropTypes.string).isRequired,
  totalInvoiceCount: PropTypes.string,
};

export default InvoiceTable;
