import Icon from 'components/base-components/Icon';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ASC, DESC } from 'utils/sort-direction';
import './sort-list.styles.scss';

const SortListComponent = ({ text, field, direction }) => {
  const [searchParams, setSearchParams] = useSearchParams({
    direction,
  });

  const setSearchQueryParams = useCallback(params => {
    Object.keys(params).forEach(key => {
      searchParams.set(key, params[key]);
    });
    setSearchParams(searchParams);
  }, []);

  const currentDirection = searchParams.get('direction');
  const currentColumn = searchParams.get('column');

  const getIconColor = direction => {
    if (currentColumn === field) {
      if (currentDirection === direction) return 'tertiary';
    }
  };

  return (
    <div className="flex sort-list__wrapper">
      {text}
      <div
        className="flex sort-list__icon"
        onClick={() =>
          setSearchQueryParams({
            direction: currentDirection === ASC ? DESC : ASC,
            column: field,
          })
        }
      >
        <Icon name="arrowUp" size="xs" color={getIconColor(ASC)} />
        <Icon name="arrowDown" size="xs" color={getIconColor(DESC)} />
      </div>
    </div>
  );
};

SortListComponent.defaultProps = {
  direction: DESC,
};

SortListComponent.propTypes = {
  text: PropTypes.string.isRequired,
  field: PropTypes.string.isRequired,
  direction: PropTypes.string,
};

export default SortListComponent;
