import { useGetSupplierCertificateHistoryQuery } from 'api/supplier-certificates';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import Pagination from 'components/base-components/Pagination/Pagination';
import {
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/base-components/Table';
import Table from 'components/base-components/Table/Table';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import EmptyPlaceholder from 'components/users/empty-placeholder';
import SUPPLIER_CERTIFICATE_TYPE from 'utils/supplier-certificate-type';
import HistoryItemsTable from './expired-declaration-table.component';

const SupplierCertificatesHistory = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  const [showHistoryItems, setShowHistoryItems] = useState(null);
  const [validDate, setValidDate] = useState({});
  const { id } = useParams();

  let currentPage = parseInt(searchParams.get('page'), 10);

  const { data: historyDataResponse } = useGetSupplierCertificateHistoryQuery({
    organizationId: id,
    page: currentPage,
    status: SUPPLIER_CERTIFICATE_TYPE.expired,
  });

  const historyData = historyDataResponse ? historyDataResponse.collection : [];

  const totalPages = historyDataResponse
    ? parseInt(historyDataResponse?.pagination?.totalPages, 10)
    : 0;

  const onChangeParams = (key, value) => {
    searchParams.append(key, value);
    setSearchParams(searchParams);
  };

  return (
    <div className="supplier-certificates__wrapper">
      {showHistoryItems ? (
        <HistoryItemsTable
          handleShowHistoryItems={() => setShowHistoryItems(false)}
          validFrom={validDate?.validFrom}
          validUntil={validDate?.validUntil}
        />
      ) : (
        <>
          {historyData && historyData.length > 0 ? (
            <>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableHeader>
                      {t('supplier_certificates.attributes.declaration_period')}
                    </TableHeader>
                    <TableHeader>
                      {t('supplier_certificates.attributes.number_of_certificate')}
                    </TableHeader>
                    <TableHeader>
                      {t('supplier_certificates.attributes.declaration_date')}
                    </TableHeader>
                    <TableHeader>{t('shared.actions')}</TableHeader>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historyData.map((history, index) => (
                    <TableRow key={index}>
                      <TableData>
                        {history?.validFrom || t('shared.tbd')} -{' '}
                        {history?.validUntil || t('shared.tbd')}
                      </TableData>
                      <TableData>
                        {history?.numberOfCertificates || t('common.not_applicable')}
                      </TableData>
                      <TableData>
                        {history?.declarationDate || t('common.not_applicable')}
                      </TableData>
                      <TableData>
                        <IconButton
                          icon={<Icon name="show" size="small" />}
                          size="small"
                          color="default"
                          onClick={() => {
                            setValidDate({
                              validFrom: history.validFrom,
                              validUntil: history.validUntil,
                            });
                            onChangeParams('declaration_id', history.id);
                            setShowHistoryItems(true);
                          }}
                        />
                      </TableData>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
              <Pagination
                onPaginationClick={newPage => {
                  onChangeParams('page', newPage);
                }}
                totalPages={totalPages}
                currentPage={currentPage}
                nextText={t('pagination.next')}
                previousText={t('pagination.prev')}
                firstText={t('pagination.first')}
                lastText={t('pagination.last')}
              />
            </>
          ) : (
            <EmptyPlaceholder
              emptyCardText={t('goods.empty_history')}
              emptyIcon={<Icon name="add" color="tertiary" />}
            />
          )}
        </>
      )}
    </div>
  );
};

export default SupplierCertificatesHistory;
