import React, { useState, useCallback } from 'react';
import fileSize from 'filesize';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { t } from 'i18next';
import classNames from 'classnames';
import Icon from '../Icon/Icon';
import IconButton from '../IconButton/IconButton';
import './fileUpload.styles.scss';

const FileUpload = props => {
  const {
    isMulti,
    onFileUpload,
    active,
    maxSize,
    fileSizeErrorMsg,
    uploadTitle,
    uploadSubtitle,
    acceptedTypes,
  } = props;
  const [files, setFiles] = useState([]);
  const [errorMsg, setErrorMsg] = useState('');

  const handleRejectedFiles = files => {
    const rejectedfile = files[0];
    if (rejectedfile.size > maxSize) {
      setErrorMsg(`${fileSizeErrorMsg} ${fileSize(maxSize)}`);
    }
  };

  const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length) {
      handleRejectedFiles(rejectedFiles);
      return;
    }

    const allFiles = [...files, ...acceptedFiles];
    setFiles(allFiles);
    onFileUpload(allFiles);
  });

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    isMulti,
    maxSize,
    accept: acceptedTypes,
  });

  const removeFile = file => {
    const newFiles = [...files];
    newFiles.splice(newFiles.indexOf(file), 1);
    setFiles(newFiles);
  };

  const acceptedFilesItems = files.map(file => (
    <li className="file-upload__file" key={file.name}>
      <div className="file-upload__file-name">
        <div className="file-upload__file-name--text">{file.name}</div>
        <div className="file-upload__vertical-line" />
        <div>{fileSize(file.size)}</div>
        <div className="file-upload__vertical-line" />
        <IconButton
          isIconOnly={true}
          icon={<Icon name="deleteLine" size="normal" />}
          onClick={() => removeFile(file)}
          color="default"
        />
      </div>
    </li>
  ));

  const label = (
    <div className="file-upload__label">
      <Icon name={errorMsg ? 'photo' : 'cloudUpload'} />
      {errorMsg ? (
        <span className="file-upload__sub-title">{errorMsg}</span>
      ) : (
        <>
          <span className="file-upload__title">{uploadTitle}</span>
          <span className="file-upload__sub-title">{uploadSubtitle}</span>
        </>
      )}
    </div>
  );

  return (
    <div className="file-upload">
      <div className="file-upload__file-list">{acceptedFilesItems}</div>
      <div
        {...getRootProps()}
        className={classNames('file-upload__dropzone', {
          'file-upload__dropzone--active': isDragActive,
        })}
      >
        <input {...getInputProps()} />
        {isDragActive ? <p> {active}</p> : label}
      </div>
    </div>
  );
};

FileUpload.defaultProps = {
  active: t('file_upload.title'),
  header: '',
  isMulti: false,
  maxSize: 5 * 1024 * 1024, // 5Mb
  onFileUpload: () => ({}),
  fileSizeErrorMsg: t('file_upload.size_error'),
  fileTypeErrorMsg: t('file_upload.type_error'),
  uploadTitle: t('file_upload.title'),
  uploadSubtitle: '',
  acceptedTypes: {},
};

FileUpload.propTypes = {
  fileSizeErrorMsg: PropTypes.string,
  fileTypeErrorMsg: PropTypes.string,
  active: PropTypes.string,
  header: PropTypes.string,
  isMulti: PropTypes.bool,
  maxSize: PropTypes.number,
  onFileUpload: PropTypes.func,
  uploadTitle: PropTypes.string,
  uploadSubtitle: PropTypes.string,
  acceptedTypes: PropTypes.shape(),
};

export default FileUpload;
