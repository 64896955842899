import React, { useState, useCallback } from 'react';
import { PillTabs } from 'components/base-components/PillTabs';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import DeactivationWrapper from 'components/organisations/deactivation-wrapper.component';
import { useSearchParams } from 'react-router-dom';
import SUPPLIER_CERTIFICATE_TYPE from 'utils/supplier-certificate-type';
import SupplierCertificatesHistory from './history/index.component';
import CertificatesList from './certificate-list.component';

const SupplierListWrapper = () => {
  const [selectedTab, setSelectedTab] = useState();
  const [searchParams, setSearchParams] = useSearchParams({ page: 1 });
  const currentPage = parseInt(searchParams.get('page'));
  const { t } = useTranslation();
  const { id } = useParams();

  const setSearchQueryParams = useCallback(
    params => {
      Object.keys(params).forEach(key => {
        searchParams.set(key, params[key]);
      });
      setSearchParams(searchParams);
    },
    [currentPage],
  );

  const onChangeTab = () => {
    setSearchQueryParams({ page: 1 });
  };

  return (
    <PillTabs
      controlled={true}
      size="tiny"
      selectedTabKey={selectedTab}
      items={[
        {
          title: t('product_declarations.draft_declaration'),
          key: 0,
          getContent: () => (
            <DeactivationWrapper>
              <CertificatesList
                key={0}
                organizationId={id}
                declarationType={SUPPLIER_CERTIFICATE_TYPE.draft}
              />
            </DeactivationWrapper>
          ),
        },
        {
          title: t('goods.tab.current_tab'),
          key: 1,
          getContent: () => (
            <DeactivationWrapper>
              <CertificatesList
                key={1}
                organizationId={id}
                declarationType={SUPPLIER_CERTIFICATE_TYPE.current}
              />
            </DeactivationWrapper>
          ),
        },
        {
          title: t('goods.tab.history'),
          key: 2,
          getContent: () => (
            <DeactivationWrapper>
              <SupplierCertificatesHistory />
            </DeactivationWrapper>
          ),
        },
      ]}
      onChange={key => {
        setSelectedTab(key);
        onChangeTab();
      }}
    />
  );
};

export default SupplierListWrapper;
