import React, { useState } from 'react';
import PropTypes from 'prop-types';
import EmphasisTag from 'components/base-components/EmphasisTag';
import IconButton from 'components/base-components/IconButton';
import Icon from 'components/base-components/Icon';
import WarningModal from 'components/shared-components/modal/warning';
import { useTranslation } from 'react-i18next';

const ComplainModal = ({ complain }) => {
  const [enableModal, setEnableModal] = useState(false);
  const { t } = useTranslation();

  return (
    <>
      <EmphasisTag
        type="warning"
        size="tiny"
        text={
          <div className="product-declarations__complain-view--tag-wrapper">
            {t('product_declarations.status.complained')}
            <div className="product-declarations__complain-view--vertical-line" />
            <IconButton
              icon={<Icon name="show" />}
              size="tiny"
              isIconOnly={true}
              onClick={() => setEnableModal(true)}
            />
          </div>
        }
      />
      <WarningModal
        onOutsideClick={() => setEnableModal(false)}
        isVisible={enableModal}
        title={t('supplier_certificates.complain')}
        content={
          <div className="supplier-certificates__complain">
            <span className="supplier-certificates__complain-header">{complain?.title}</span>
            <span className="supplier-certificates__complain-body">{complain?.description}</span>
          </div>
        }
        confirmActionText={t('shared.action.okay')}
        cancelActionText={t('shared.action.cancel')}
        onCancel={() => setEnableModal(false)}
        onConfirm={() => setEnableModal(false)}
      />
    </>
  );
};

ComplainModal.defaultProps = {
  complain: null,
};

ComplainModal.propTypes = {
  complain: PropTypes.shape(),
};

export default ComplainModal;
