import routes from 'utils/routes';
import { INVOICE_FLAT_FILE } from 'utils/tag-types';
import { apiSlice } from '../index';

const DocumentsApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    uploadFlatFiles: build.mutation({
      query: ({ params, payload }) => ({
        url: routes.documents.collection(params),
        method: 'POST',
        body: payload,
      }),
      invalidatesTags: [INVOICE_FLAT_FILE],
    }),
    getFlatFiles: build.query({
      query: params => routes.documents.collection(params),
      providesTags: [INVOICE_FLAT_FILE],
    }),
    convertFlatFile: build.mutation({
      query: documentId => ({
        url: routes.documents.convertFile({ documentId }),
        method: 'PUT',
      }),
      invalidatesTags: [INVOICE_FLAT_FILE],
    }),
  }),

  overrideExisting: false,
});

export const { useUploadFlatFilesMutation, useGetFlatFilesQuery, useConvertFlatFileMutation } =
  DocumentsApi;
