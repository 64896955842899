import { useConvertFlatFileMutation } from 'api/documents';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton';
import Link from 'components/base-components/Link';
import Popover from 'components/base-components/Popover';
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeader,
  TableRow,
} from 'components/base-components/Table';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import TableLoaderPlaceholder from 'components/shared-components/placeholders/table-loader-placeholder';
import UserInfo from 'components/users/user-info.component';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { dateMonthYear } from 'utils/date-time-formatter';
import { UPLOADED } from 'utils/flat-file-types';
import { toSnakecaseKeys } from 'utils/object-cleanup';
import truncateFilename from 'utils/truncate-filename';

const FlatFileName = ({ files }) => (
  <div className="invoice__flat-file--name">
    {files.map(file => (
      <Link key={file.id} modifier="info" href={file?.url}>
        {truncateFilename(file?.filename, 34)}
      </Link>
    ))}
  </div>
);

const InvoiceFlatFileTableRow = ({ flatFile, currentStatuses, onConvert }) => {
  const { id, uploadedByUserId } = flatFile;
  const files = flatFile?.files;
  const [showPopover, setShowPopover] = useState(false);

  return (
    <>
      <TableRow className="organisation__table-row">
        <TableData className="invoice__flat-file--name-column">
          <FlatFileName files={files} />
        </TableData>
        <TableData>{dateMonthYear(files[0].uploadedAt)}</TableData>
        <TableData>
          <div className="invoice-list-flat-file--user-info">
            <UserInfo key={uploadedByUserId} userId={uploadedByUserId} />
          </div>
        </TableData>
        {currentStatuses === UPLOADED && (
          <TableData align="right">
            <Popover
              className="task__popover-wrapper"
              isVisible={showPopover}
              onOutsideClick={() => setShowPopover(false)}
              content={
                <div className="task__popover">
                  <div
                    className="task__popover-content"
                    onClick={() => {
                      onConvert(id);
                      setShowPopover(false);
                    }}
                  >
                    {t('invoice.flat_file.table.popovers.convert')}
                  </div>
                </div>
              }
            >
              <IconButton
                icon={<Icon name="horizontalDots" />}
                color="default"
                onClick={() => setShowPopover(!showPopover)}
                isIconOnly={false}
              />
            </Popover>
          </TableData>
        )}
      </TableRow>
    </>
  );
};

export const InvoiceFlatFileTableHeader = ({ currentStatuses }) => (
  <TableHead>
    <TableRow>
      <TableHeader>{t('invoice.flat_file.table.header.file_name')}</TableHeader>
      {currentStatuses === UPLOADED ? (
        <>
          <TableHeader>{t('invoice.flat_file.table.header.upload_date')}</TableHeader>
          <TableHeader>{t('invoice.flat_file.table.header.uploaded_by')}</TableHeader>
          <TableHeader align="right">{t('shared.actions')}</TableHeader>
        </>
      ) : (
        <>
          <TableHeader>{t('invoice.flat_file.table.header.conversion_date')}</TableHeader>
          <TableHeader>{t('invoice.flat_file.table.header.converted_by')}</TableHeader>
        </>
      )}
    </TableRow>
  </TableHead>
);

const InvoiceFlatFileTable = ({ flatFiles, isFetching, currentStatuses }) => {
  const [convertFlatFile, { isLoading, isSuccess, isError, requestId }] =
    useConvertFlatFileMutation();

  const onConvert = id => {
    return convertFlatFile(id)
      .unwrap()
      .catch(({ data: { errors } }) => toSnakecaseKeys(errors));
  };

  if (!isFetching && flatFiles?.length === 0) {
    return <EmptyContentPlaceholder iconName="invoice" text={t('invoice.no_invoice')} />;
  }

  return (
    <>
      <Table className="organisation_table">
        <InvoiceFlatFileTableHeader currentStatuses={currentStatuses} />
        <TableBody>
          {isFetching ? (
            <TableLoaderPlaceholder numberOfRows={10} numberOfColumns={4} />
          ) : (
            flatFiles?.map(flatFile => (
              <InvoiceFlatFileTableRow
                key={flatFile?.id}
                flatFile={flatFile}
                onConvert={onConvert}
                currentStatuses={currentStatuses}
              />
            ))
          )}
        </TableBody>
      </Table>
      <SubmitModal
        isSuccess={isSuccess}
        isLoading={isLoading}
        isError={isError}
        requestId={requestId}
        successTitle={t('invoice.flat_file.modal.success_title')}
        successContent={t('invoice.flat_file.modal.success_content')}
        errorTitle={t('invoice.flat_file.modal.error_title')}
        showError={true}
        showLoader={true}
      />
    </>
  );
};

InvoiceFlatFileTableHeader.propTypes = {
  currentStatuses: PropTypes.string.isRequired,
};

InvoiceFlatFileTableRow.propTypes = {
  flatFile: PropTypes.shape().isRequired,
  currentStatuses: PropTypes.string.isRequired,
  onConvert: PropTypes.func.isRequired,
};

InvoiceFlatFileTable.defaultProps = {
  isFetching: false,
  organisationId: null,
  enableBulkAction: false,
  totalInvoiceCount: '',
};

InvoiceFlatFileTable.propTypes = {
  flatFiles: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isFetching: PropTypes.bool,
  currentStatuses: PropTypes.string.isRequired,
};

FlatFileName.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

export default InvoiceFlatFileTable;
