import {
  ORGANISATION,
  ORGANISATION_DECLARATION_STATUS,
  ORGANISATION_OVERVIEW,
} from 'utils/tag-types';
import routes from 'utils/routes';
import { apiSlice } from '../index';

const organizationsApi = apiSlice.injectEndpoints({
  endpoints: build => ({
    getOrganizations: build.query({
      query: searchParams => routes.organizations.collection(searchParams),
      transformResponse: (response, meta, arg) => {
        return {
          organizations: response.collection,
          pagination: response.pagination,
          tab: arg && arg.status,
        };
      },
      providesTags: (result, error, arg) => {
        return result
          ? [
              ...result.organizations.map(({ id }) => ({ type: ORGANISATION, id })),
              { type: ORGANISATION, id: arg.status ? arg.status : 'all' },
            ]
          : [{ type: ORGANISATION, id: 'all' }];
      },
    }),
    getOrganizationAssignments: build.query({
      query: params => routes.organizations.assignments(params),
      providesTags: [ORGANISATION],
    }),
    getOrganizationDeclarationStatus: build.query({
      query: params => routes.organizations.declarationStatus(params),
      providesTags: [ORGANISATION_DECLARATION_STATUS],
    }),
    getOrganization: build.query({
      query: organizationId => routes.organizations.entity({ organizationId }),
      providesTags: [ORGANISATION],
    }),
    getOrganizationHealth: build.query({
      query: organizationId => routes.organizations.health({ organizationId }),
    }),
    getAdminOrganizationOverview: build.query({
      query: () => routes.organizations.overview(),
      providesTags: [ORGANISATION_OVERVIEW],
    }),
    getUserOrganizationOverview: build.query({
      query: id => routes.organizations.entityOfOverview({ id }),
      providesTags: [ORGANISATION_OVERVIEW],
    }),
    getSpecificOrganizationsOverview: build.query({
      query: organizationId =>
        routes.organizations.specificOrganizationOverview({ organizationId }),
      providesTags: [ORGANISATION_OVERVIEW],
    }),
    getOrganisationRoles: build.query({
      query: () => routes.roles.collectionOfOrganisation(),
    }),
    getOrganisationStartingYears: build.query({
      query: () => routes.organizations.startingYears(),
    }),
    getOrganisationTitles: build.query({
      query: () => routes.organizations.titles(),
    }),
    getOrganisationHistory: build.query({
      query: params => routes.organizations.history(params),
      providesTags: [ORGANISATION_OVERVIEW],
    }),
    getOrganizationRegistry: build.query({
      query: params => routes.organizations.registryLookup(params),
    }),
    getLegalFormOptions: build.query({
      query: params => routes.organizations.legalFormOptions(params),
    }),
    checkAlreadyRegisteredOrganization: build.query({
      query: params => routes.organizations.registered(params),
    }),
    createOrganization: build.mutation({
      query: params => ({
        url: routes.organizations.collection(params),
        method: 'POST',
        body: params,
      }),
      invalidatesTags: [ORGANISATION],
    }),
    updateOrganization: build.mutation({
      query: ({ formData, id }) => ({
        url: routes.organizations.entity({ organizationId: id }),
        method: 'PATCH',
        body: formData,
      }),
      invalidatesTags: result => (result ? [ORGANISATION] : []),
    }),
    deactivateOrganization: build.mutation({
      query: id => ({
        url: routes.organizations.deactivate({ id }),
        method: 'DELETE',
        credentials: 'include',
      }),
    }),
    reactivateOrganization: build.mutation({
      query: id => ({
        url: routes.organizations.deactivate({ id }),
        method: 'PUT',
      }),
    }),
    getOrganizationIndustries: build.query({
      query: () => routes.organizations.industries(),
    }),
    getMembershipOrganizations: build.query({
      query: () => routes.organizations.membershipOrganizations(),
    }),
    getContractUsers: build.query({
      query: params => routes.organizations.contractUsers(params),
      providesTags: [ORGANISATION],
    }),
    getPreviousCultivationTypeOptions: build.query({
      query: () => routes.organizations.previousCultivationTypeOptions(),
      providesTags: [ORGANISATION],
    }),
    validateOrganization: build.mutation({
      query: params => ({
        url: routes.organizations.getValidation(params),
        method: 'PUT',
        body: params,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetOrganizationsQuery,
  useGetOrganizationQuery,
  useGetOrganizationHealthQuery,
  useGetOrganisationRolesQuery,
  useGetOrganizationIndustriesQuery,
  useCreateOrganizationMutation,
  useUpdateOrganizationMutation,
  useDeactivateOrganizationMutation,
  useReactivateOrganizationMutation,
  useGetOrganizationRegistryQuery,
  useGetLegalFormOptionsQuery,
  useCheckAlreadyRegisteredOrganizationQuery,
  useGetOrganizationAssignmentsQuery,
  useGetAdminOrganizationOverviewQuery,
  useGetUserOrganizationOverviewQuery,
  useGetSpecificOrganizationsOverviewQuery,
  useGetOrganisationTitlesQuery,
  useGetOrganisationHistoryQuery,
  useGetOrganizationDeclarationStatusQuery,
  useGetMembershipOrganizationsQuery,
  useGetOrganisationStartingYearsQuery,
  useGetPreviousCultivationTypeOptionsQuery,
  useGetContractUsersQuery,
  useValidateOrganizationMutation,
} = organizationsApi;
