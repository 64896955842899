import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { PillTabs } from 'components/base-components/PillTabs';
import { useGetCurrentUserQuery, useGetUsersQuery } from 'api/users';
import { Tabs } from 'components/base-components/RTabs';
import Pagination from 'components/base-components/Pagination';
import { USERS_STATUS } from 'utils/statuses';
import HeaderComponent from 'components/shared-components/header-component';
import isControlBodyMember from 'utils/is-control-body-member';
import { CONTROL_BODY_USER } from 'utils/users-role';
import TabContent from './tab-content.component';
import '../users.styles.scss';

const UsersList = ({ isOrganisationView, organizationId }) => {
  const [searchParams, setSearchParams] = useSearchParams({ tab: 'active', q: '', page: 1 });
  const { t } = useTranslation();

  const currentTab = searchParams.get('tab');
  const currentDirection = searchParams.get('direction');
  const currentColumn = searchParams.get('column');
  const currentPage = parseInt(searchParams.get('page'), 10);
  const { data: currentUser = {} } = useGetCurrentUserQuery();

  const userParams = {
    q: searchParams.get('q'),
    status: USERS_STATUS[currentTab],
    page: currentPage,
    'organization_ids[]': [organizationId],
    'types[]': isControlBodyMember(currentUser) && !organizationId ? [CONTROL_BODY_USER] : [],
    direction: currentDirection,
    column: currentColumn,
  };

  const { isFetching, data } = useGetUsersQuery(userParams);
  const users = data ? data.users : [];

  const TAB_MAPPER = {
    active: 0,
    archived: 1,
    invited: 2,
  };

  const onChangeTab = selectedTabKey => {
    const selectedTab = Object.keys(TAB_MAPPER).find(tab => TAB_MAPPER[tab] === selectedTabKey);
    setSearchQueryParams({ tab: selectedTab, page: 1 });
  };

  const getPagination = () => {
    return (
      <Pagination
        onPaginationClick={newPage => {
          setSearchQueryParams({
            page: newPage,
            direction: currentDirection,
            column: currentColumn,
          });
        }}
        totalPages={data && parseInt(data.pagination.totalPages, 10)}
        currentPage={currentPage}
        nextText={t('pagination.next')}
        previousText={t('pagination.prev')}
        firstText={t('pagination.first')}
        lastText={t('pagination.last')}
      />
    );
  };

  const setSearchQueryParams = useCallback(
    params => {
      Object.keys(params).forEach(key => {
        searchParams.set(key, params[key]);
      });
      setSearchParams(searchParams);
    },
    [currentTab],
  );

  const getTabItems = () => {
    return [
      {
        title: t('user.active_user_tab'),
        key: 0,
        getContent: () => (
          <TabContent
            tab="active"
            key="active"
            onChangeParams={setSearchQueryParams}
            users={users}
            isLoading={isFetching}
            organizationId={organizationId}
            isOrganisationView={isOrganisationView}
          />
        ),
      },
      {
        title: t('user.archived_user_tab'),
        key: 1,
        getContent: () => (
          <TabContent
            tab="archived"
            key="archived"
            onChangeParams={setSearchQueryParams}
            users={users}
            isLoading={isFetching}
            organizationId={organizationId}
            isOrganisationView={isOrganisationView}
          />
        ),
      },
      {
        title: t('user.invited_user_tab'),
        key: 2,
        getContent: () => (
          <TabContent
            tab="invited"
            key="invited"
            onChangeParams={setSearchQueryParams}
            users={users}
            isLoading={isFetching}
            organizationId={organizationId}
            isOrganisationView={isOrganisationView}
          />
        ),
      },
    ];
  };

  return (
    <div className="user__list">
      {!isOrganisationView && <HeaderComponent />}

      {isOrganisationView ? (
        <div className="user__pill-tabs">
          <PillTabs
            controlled={true}
            size="tiny"
            selectedTabKey={TAB_MAPPER[currentTab]}
            items={getTabItems()}
            onChange={onChangeTab}
          />
          {getPagination()}
        </div>
      ) : (
        <div className="user__list--rtabs">
          <Tabs
            controlled={true}
            items={getTabItems()}
            selectedTabKey={TAB_MAPPER[currentTab]}
            onChange={onChangeTab}
          />
          {getPagination()}
        </div>
      )}
    </div>
  );
};

UsersList.defaultProps = {
  isOrganisationView: false,
  organizationId: null,
};

UsersList.propTypes = {
  isOrganisationView: PropTypes.bool,
  organizationId: PropTypes.number,
};

export default UsersList;
