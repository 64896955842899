import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Icon from 'components/base-components/Icon';
import IconButton from 'components/base-components/IconButton/IconButton';
import Popover from 'components/base-components/Popover';
import TableLoaderPlaceholder from 'components/shared-components/placeholders/table-loader-placeholder';
import EmptyContentPlaceholder from 'components/shared-components/placeholders/empty-content-placeholder-component';
import STARS_STATUS from 'utils/star_status';
import { useGetOrganizationHealthQuery } from 'api/organizations';
import {
  Table,
  TableHeader,
  TableHead,
  TableRow,
  TableData,
  TableBody,
} from 'components/base-components/Table';
import SortListComponent from 'components/shared-components/sort-list.component';
import { Link } from 'react-router-dom';
import '../organisations.styles.scss';
import Spinner from 'components/base-components/Spinner';
import ORGANISATION_TYPE from 'utils/organisation-type';
import useNavigationLinks from 'hooks/useNavigationLinks';
import { dateMonthYear } from 'utils/date-time-formatter';
import EmphasisTag from '../../base-components/EmphasisTag';
import HealthStatus from '../health-status';
import OrganizationName from '../organization-name';
import BioCuisineStatus from '../bio-cuisine-status.component';
import { ORGANISATIONS_STATUS } from '../../../utils/statuses';
import { useGetCurrentUserQuery } from '../../../api/users';
import isBioSuisseAdmin from '../../../utils/is-bio-suisse-admin';
import isControlBodyMember from '../../../utils/is-control-body-member';

const OrganizationsTableRow = ({ organization, isAdmin, parentOrganisationId }) => {
  const [isSubOrganizationsVisible, setIsSubOrganizationsVisible] = useState(false);
  const { data: currentUser = null } = useGetCurrentUserQuery();
  const isControlBodyUser = isControlBodyMember(currentUser);
  const {
    isSuccess,
    isFetching,
    data: health = {},
  } = useGetOrganizationHealthQuery(organization.id, {
    skip: !isAdmin || organization.type === ORGANISATION_TYPE.parent_organisation,
  });
  const { t } = useTranslation();
  const navigationLinks = useNavigationLinks();

  const totalSubOrganizations = organization?.subOrgIds?.length;
  const totalUsers = organization.userCount;

  let healthStatus = t('shared.no_availability');

  if (isFetching) {
    healthStatus = <Spinner bgColor="none" color="success" size="tiny" />;
  } else if (isSuccess && health) {
    healthStatus = (
      <HealthStatus
        status={health.type}
        task_count={health.pendingTasksCount}
        messages_count={health.unconfirmedMessagesCount}
      />
    );
  }

  return (
    <TableRow className="organisation__table-row">
      <TableData>{organization.bioId || t('common.not_applicable')}</TableData>
      {parentOrganisationId ? (
        <TableData className="organisation__table-row--violet-color">
          <Link
            className="organisation__table-row--name"
            to={navigationLinks.organisationDetailsPage(organization.id)}
          >
            {organization.name}
          </Link>
        </TableData>
      ) : (
        <TableData className="organisation__table-row--violet-color">
          <Link
            className="organisation__table-row--name"
            to={
              organization.type === ORGANISATION_TYPE.controlBody
                ? navigationLinks.organisationViewPage(organization.id)
                : navigationLinks.organisationDetailsPage(organization.id)
            }
          >
            {organization.name}
          </Link>
        </TableData>
      )}
      <TableData>
        {organization.firstLegitimizedAt
          ? dateMonthYear(organization.firstLegitimizedAt)
          : t('common.not_applicable')}
      </TableData>
      <TableData align="left">
        <EmphasisTag
          className="emphasis-tag--left-margin-none"
          type="normal"
          size="tiny"
          text={
            organization.type === ORGANISATION_TYPE.controlBody
              ? t('organisation.details.tabs.control')
              : t(`organisation.industry.${organization.industry}`)
          }
        />
      </TableData>
      <TableData>
        {organization?.bioCuisineStars === 0 ? (
          <span className="organisation__stars-not-certified">
            {t('bio_cuisine_status.not_certified')}
          </span>
        ) : organization?.bioCuisineStars ? (
          <BioCuisineStatus
            status={STARS_STATUS[organization?.bioCuisineStars]}
            isDowngraded={organization.downgraded}
          />
        ) : (
          t('common.not_applicable')
        )}
      </TableData>
      {!isControlBodyUser && (
        <TableData
          className={classNames({
            'organisation__table-row--violet-color organisation__table-row--members':
              totalUsers > 0,
          })}
        >
          {t('organisation.members.total', { count: totalUsers })}
        </TableData>
      )}
      {isAdmin && <TableData>{healthStatus}</TableData>}
      {!isControlBodyUser && !parentOrganisationId && (
        <TableData>
          {organization.parentId ? (
            <OrganizationName
              className="organisation__table-row--violet-color"
              key={`main-org-${organization.parentId}`}
              organizationId={organization.parentId}
            />
          ) : (
            t('shared.no_availability')
          )}
        </TableData>
      )}
      {!isControlBodyUser && !parentOrganisationId && (
        <TableData>
          <Popover
            isVisible={isSubOrganizationsVisible && totalSubOrganizations > 0}
            size="medium"
            content={
              <div className="organisation__popover">
                {organization.subOrgIds.map(subOrgId => (
                  <Link
                    to={navigationLinks.organisationDetailsPage(subOrgId)}
                    className="organisation__link"
                  >
                    <div className="organisation__popover-content">
                      <OrganizationName
                        key={`sub-org-${subOrgId}`}
                        organizationId={subOrgId}
                        disableLink={true}
                      />
                    </div>
                  </Link>
                ))}
              </div>
            }
            direction="bottom-right"
            onOutsideClick={() => setIsSubOrganizationsVisible(false)}
          >
            <span onClick={() => setIsSubOrganizationsVisible(!isSubOrganizationsVisible)}>
              {totalSubOrganizations === 0 ? (
                t('shared.no_availability')
              ) : (
                <span className="organisation__table-row--sub-organizations-multiple organisation__table-row--violet-color">
                  {t('organisation.sub_organizations', { count: totalSubOrganizations })}
                </span>
              )}
            </span>
          </Popover>
        </TableData>
      )}
      <TableData align="center">
        <div className="organisation__action" role="button">
          <Link
            to={
              organization.type === ORGANISATION_TYPE.controlBody
                ? navigationLinks.organisationViewPage(organization.id)
                : navigationLinks.organisationDetailsPage(organization.id)
            }
          >
            <IconButton
              className="organisation__action-show"
              icon={<Icon name="show" size="small" />}
              size="tiny"
              color="tertiary"
            />
          </Link>
          {isBioSuisseAdmin(currentUser) &&
            (organization.status === ORGANISATIONS_STATUS.inactive ? (
              <Link to={navigationLinks.organisationReactivePage(organization.id)}>
                <IconButton
                  className="organisation__action-reactive"
                  icon={<Icon name="powerOff" size="small" />}
                  size="tiny"
                  color="tertiary"
                />
              </Link>
            ) : (
              <Link to={navigationLinks.organisationsEditPage(organization.id)}>
                <IconButton
                  className="organisation__action-edit"
                  icon={<Icon name="edit" size="small" />}
                  size="tiny"
                  color="tertiary"
                />
              </Link>
            ))}
        </div>
      </TableData>
    </TableRow>
  );
};

OrganizationsTableRow.defaultProps = {
  isAdmin: false,
  parentOrganisationId: null,
};

OrganizationsTableRow.propTypes = {
  organization: PropTypes.shape().isRequired,
  isAdmin: PropTypes.bool,
  parentOrganisationId: PropTypes.node,
};

const OrganizationsTable = ({ organizations, isLoading, parentOrganisationId }) => {
  const { data: currentUser } = useGetCurrentUserQuery();
  const { t } = useTranslation();
  const isAdmin = isBioSuisseAdmin(currentUser);
  const isControlBodyUser = isControlBodyMember(currentUser);

  const OrganisationListTableHeader = () => (
    <TableHead>
      <TableRow>
        <TableHeader>
          <SortListComponent
            text={t('organisation.common_view.table_head.bio_id')}
            field="bio_id"
          />
        </TableHeader>
        {isControlBodyUser ? (
          <TableHeader>{t('organisation.admin_view.table_head.name')}</TableHeader>
        ) : (
          <TableHeader>
          <SortListComponent
            text={t('organisation.admin_view.table_head.organisation')}
            field="name"
          />
          </TableHeader>
        )}
        <TableHeader>
        <SortListComponent
          text={t('organisation.common_view.table_head.first_legitimization')}
          field="first_legitimized_at"
        />
        </TableHeader>
        <TableHeader>{t('organisation.admin_view.table_head.department')}</TableHeader>
        <TableHeader>{t('organisation.common_view.table_head.status')}</TableHeader>
        {!isControlBodyUser && (
          <TableHeader>{t('organisation.common_view.table_head.members')}</TableHeader>
        )}
        {isAdmin && <TableHeader>{t('organisation.admin_view.table_head.health')}</TableHeader>}
        {!isControlBodyUser && !parentOrganisationId && (
          <TableHeader>
          <SortListComponent text={t('organisation.main_organisation')} field="parent_id" />
          </TableHeader>
        )}
        {!isControlBodyUser && !parentOrganisationId && (
          <TableHeader>{t('organisation.common_view.table_head.sub_organisation')}</TableHeader>
        )}
        <TableHeader align="center">{t('organisation.common_view.table_head.actions')}</TableHeader>
      </TableRow>
    </TableHead>
  );

  return (
    <>
      {!isLoading && organizations.length === 0 ? (
        <EmptyContentPlaceholder
          iconName="organization"
          text={t('organisation.user_view.table_head.no_organisations')}
        />
      ) : (
        <Table className="organisation_table">
          <OrganisationListTableHeader />
          <TableBody>
            {isLoading ? (
              <TableLoaderPlaceholder
                numberOfRows={10}
                numberOfColumns={isAdmin ? 8 : isControlBodyUser ? 4 : 7}
              />
            ) : (
              organizations.map(organization => (
                <OrganizationsTableRow
                  key={`organization-list-${organization.id}`}
                  isAdmin={isAdmin}
                  organization={organization}
                  parentOrganisationId={parentOrganisationId}
                />
              ))
            )}
          </TableBody>
        </Table>
      )}
    </>
  );
};

OrganizationsTable.defaultProps = {
  isLoading: false,
  parentOrganisationId: null,
};

OrganizationsTable.propTypes = {
  organizations: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  isLoading: PropTypes.bool,
  parentOrganisationId: PropTypes.node,
};

export default OrganizationsTable;
