import React, { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useGetInvoiceGroupsQuery } from 'api/invoices';
import PropTypes from 'prop-types';
import InvoiceGroupsTable from 'components/invoices/shared/groups-table.component';
import SharedInputFilterCreateComponent from 'components/invoices/shared/input-filter-create.component';
import { t } from 'i18next';
import Button from 'components/base-components/Button';
import { Link } from 'react-router-dom';
import useNavigationLinks from 'hooks/useNavigationLinks';

const InvoiceGroupListComponent = ({ currentTab }) => {
  const navigationLinks = useNavigationLinks();
  const [searchParams, setSearchParams] = useSearchParams({
    q: '',
    page: 1,
    statuses: [],
  });

  const currentPage = parseInt(searchParams.get('page'));
  const searchQuery = searchParams.get('q');
  const statuses = currentTab ? [currentTab] : searchParams.getAll('statuses');

  const invoiceParams = {
    q: searchQuery,
    'statuses[]': statuses,
    page: currentPage,
  };

  const setSearchQueryParams = useCallback(params => {
    Object.keys(params).forEach(key => {
      searchParams.set(key, params[key]);
    });
    setSearchParams(searchParams);
  }, []);

  const { data: invoiceCollection = {}, isFetching } = useGetInvoiceGroupsQuery(invoiceParams);
  const invoices = invoiceCollection?.collection || [];

  return (
    <SharedInputFilterCreateComponent
      searchQuery={searchQuery}
      setSearchQueryParams={setSearchQueryParams}
      currentTab={currentTab}
      currentPage={currentPage}
      navigateToNewPage={true}
      collection={invoiceCollection}
      searchInputPlaceholder={t('invoice.search_invoice')}
      type="invoiceGroup"
      createButton={
        <Link to={navigationLinks.invoiceCreatePage()}>
          <Button type="success" size="small" label={t('invoice.create_new_invoice')} />
        </Link>
      }
    >
      <InvoiceGroupsTable invoices={invoices} isFetching={isFetching} currentTab={currentTab} />
    </SharedInputFilterCreateComponent>
  );
};

InvoiceGroupListComponent.defaultProps = {
  currentTab: null,
  enableBulkAction: false,
};

InvoiceGroupListComponent.propTypes = {
  currentTab: PropTypes.string,
  enableBulkAction: PropTypes.bool,
};

export default InvoiceGroupListComponent;
