import React, { useCallback } from 'react';

import { useSearchParams } from 'react-router-dom';
import { useGetInvoiceJobsQuery } from 'api/invoices';
import PropTypes from 'prop-types';
import InvoiceJobsTable from 'components/invoices/shared/jobs-table.component';
import SharedInputFilterCreateComponent from 'components/invoices/shared/input-filter-create.component';
import { t } from 'i18next';
import Button from 'components/base-components/Button';

const InvoiceJobListComponent = ({ currentTab }) => {
  const [searchParams, setSearchParams] = useSearchParams({
    q: '',
    page: 1,
  });

  const currentPage = parseInt(searchParams.get('page'));
  const searchQuery = searchParams.get('q');

  const invoiceParams = {
    q: searchQuery,
    page: currentPage,
  };

  const setSearchQueryParams = useCallback(params => {
    Object.keys(params).forEach(key => {
      searchParams.set(key, params[key]);
    });
    setSearchParams(searchParams);
  }, []);

  const { data: invoiceJobsCollection = {}, isFetching } = useGetInvoiceJobsQuery(invoiceParams);
  const invoiceJobs = invoiceJobsCollection?.collection || [];

  return (
    <SharedInputFilterCreateComponent
      searchQuery={searchQuery}
      setSearchQueryParams={setSearchQueryParams}
      currentTab={currentTab}
      currentPage={currentPage}
      collection={invoiceJobsCollection}
      searchInputPlaceholder={t('invoice.search_invoice')}
      type="invoiceJob"
      createButton={<Button type="success" size="small" label={t('invoice.create_new_job')} />}
      sidePanelTitle={t('invoice.create_new_job')}
    >
      <InvoiceJobsTable invoiceJobs={invoiceJobs} isFetching={isFetching} />
    </SharedInputFilterCreateComponent>
  );
};

InvoiceJobListComponent.defaultProps = {
  currentTab: null,
  enableBulkAction: false,
};

InvoiceJobListComponent.propTypes = {
  currentTab: PropTypes.string,
  enableBulkAction: PropTypes.bool,
};

export default InvoiceJobListComponent;
