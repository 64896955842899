import { useUploadFlatFilesMutation } from 'api/documents';
import Button from 'components/base-components/Button';
import FileUpload from 'components/base-components/FileUpload';
import SubmitModal from 'components/shared-components/modal/submit-modal';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import { Field, Form } from 'react-final-form';

const FlatFileForm = ({ setIsSidepanelOpen }) => {
  const [uploadFiles, { isSuccess, isLoading, isError, requestId }] = useUploadFlatFilesMutation();

  const onSubmit = values => {
    let formData = new FormData();

    (values.files || []).forEach(file => {
      formData.append('files[]', file);
    });

    return uploadFiles({ payload: formData }).unwrap();
  };

  return (
    <Form
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Field name="files">
              {({ input }) => (
                <FileUpload
                  isMulti={true}
                  onFileUpload={files => {
                    const existingFiles = input.value;
                    input.onChange([...existingFiles, ...files]);
                  }}
                  acceptedTypes={{
                    'text/plain': ['.txt'],
                  }}
                />
              )}
            </Field>
            <div className="margin-top-20">
              <Button
                type="success"
                label={t('shared.action.upload')}
                size="small"
                disabled={!values?.files}
                submitType="submit"
              />
              <Button
                className="margin-left-10"
                label={t('shared.action.cancel')}
                size="small"
                onClick={() => setIsSidepanelOpen(false)}
              />
            </div>
            <SubmitModal
              isSuccess={isSuccess}
              isLoading={isLoading}
              isError={isError}
              requestId={requestId}
              loaderContent={t('invoice.flat_file.upload_modal.loader_content')}
              successTitle={t('invoice.flat_file.upload_modal.success_title')}
              successContent={t('invoice.flat_file.upload_modal.success_content')}
              errorTitle={t('invoice.flat_file.upload_modal.error_title')}
              errorContent={t('invoice.flat_file.upload_modal.error_content')}
              showError={true}
              showLoader={true}
              onSuccess={() => setIsSidepanelOpen(false)}
            />
          </form>
        );
      }}
    />
  );
};

FlatFileForm.defaultProps = {
  setIsSidepanelOpen: () => {},
};

FlatFileForm.propTypes = {
  setIsSidepanelOpen: PropTypes.func,
};

export default FlatFileForm;
