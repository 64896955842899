import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/base-components/Icon';
import EmphasisTag from 'components/base-components/EmphasisTag';
import classNames from 'classnames';
import { dateMonthYear } from 'utils/date-time-formatter';
import { t } from 'i18next';

const HistoryItem = ({ history, isLastItem }) => {
  const historyReason = history?.reason;
  const actionText = historyReason?.slice(historyReason?.lastIndexOf('_') + 1);

  return (
    <div className="history__item">
      <div className="history__item--timeline">
        <span>{history?.createdAt && dateMonthYear(history.createdAt)}</span>
        <div className="history__item--timeline-icon-group">
          <Icon className="history__item--timeline-check" name="checkCircle" size="medium" />
          {!isLastItem && <div className="history__item--timeline-vertical-line"></div>}
        </div>
      </div>
      <div className="history__item--info">
        <div className="history__item--info-title">
          {t(`organisation.history.${historyReason}`, {
            previous: history?.metadata?.previousWas,
            current: history?.metadata?.changedTo,
          })}
        </div>
        <div
          className={classNames('history__item--info-description', {
            'history__item--info-description-no-margin': isLastItem,
          })}
        >
          <div>
            <span className="history__item--info-description-status">
              {t(`organisation.history.actions.${actionText}`)}:
            </span>
            <span className="history__item--info-description-username">{history?.user?.name}</span>
            {history?.user && (
              <EmphasisTag
                className="history__item--info-description-user-role"
                type="normal"
                text={t(`role.${history?.user?.role}`)}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

HistoryItem.propTypes = {
  history: PropTypes.shape().isRequired,
  isLastItem: PropTypes.bool.isRequired,
};

export default HistoryItem;
